import { useEffect } from "react";

export default function DMCA() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'DMCA Policy | Stickman Hook';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Learn about Stickman Hook's DMCA policy. Submit takedown notices or counter-notifications for copyright infringement on our platform. Protect your intellectual property rights.");
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Stickman Hook - Play Stickman Hook Unblocked Game';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Play Stickman Hook for free on the online gaming website! Challenge yourself with unique grappling levels and overcome thrilling obstacles. Conquer Stickman Hook now!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://stickmanhookgame.com/');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-14vh)] lg:min-h-[calc(100vh-15vh)] md:min-h-[calc(100vh-10vh)] mb-4 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        DMCA Notice for Stickman Hook
                    </h1>
                    <br />
                    <span className="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Stickman Hook respects intellectual property rights and follows the Digital Millennium Copyright Act (DMCA). If you believe your copyrighted work has been infringed upon, you can submit a DMCA takedown notice to us. We will review your claim and take appropriate action.
                    </span>
                    <br />
                    <br />
                    <h2 className="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        How to Submit a DMCA Takedown Notice
                    </h2>
                    <br />
                    <div className="des-game 2xl:text-lg lg:text-lg text-base">
                        Please provide the following details:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                Your signature (physical or electronic).
                            </li>
                            <li>
                                Identification of the copyrighted work.
                            </li>
                            <li>
                                Identification of the infringing material (URL or link).
                            </li>
                            <li>
                                Your contact information (name, address, phone, email).
                            </li>
                            <li>
                                A statement that the use is unauthorized.
                            </li>
                            <li>
                                A statement that the information is accurate.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 className="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Counter-Notification
                    </h2>
                    <br />
                    <div className="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        If you believe your content was removed in error, you can submit a counter-notification with:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                Your full name and contact info.
                            </li>
                            <li>
                                The location of the removed material.
                            </li>
                            <li>
                                A statement that the removal was a mistake.
                            </li>
                            <li>
                                Your consent to jurisdiction.
                            </li>
                            <li>
                                Your signature (physical or electronic).
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 className="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Contact DMCA Agent
                    </h2>
                    <br />
                    <div className="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        For takedown notices or counter-notifications, please contact our DMCA agent at:
                    </div>
                    <br />
                    <div className="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Email: <a href="mailto:support@stickmanhookgame.com" className=" text-blue-500">{`support@stickmanhookgame.com`}</a>
                    </div>
                    <br />
                    <div className="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Stickman Hook is not responsible for user-uploaded content but will respond to valid DMCA claims promptly. For any questions or assistance, please contact us.
                    </div>
                </div>
            </div>
        </div>
    )
}