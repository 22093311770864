import { useEffect } from "react";

export default function TermOfUse() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Terms of Use | Stickman Hook';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Review the Terms of Use for Stickman Hook to understand our policies on site access, content usage, and user responsibilities.");
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Stickman Hook - Play Stickman Hook Unblocked Game';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Play Stickman Hook for free on the online gaming website! Challenge yourself with unique grappling levels and overcome thrilling obstacles. Conquer Stickman Hook now!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://stickmanhookgame.com/');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-18vh)] text-[#383838] mb-10">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        Terms of Use for Stickman Hook
                    </h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Thank you for visiting Stickman Hook. By accessing or using our website and game, you agree to the following Terms of Use. Please review these terms carefully, as they govern your rights and responsibilities while using our services.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        1. Acceptance of Terms
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        By using Stickman Hook, you agree to comply with and be bound by these Terms of Use, as well as any additional terms and conditions that may apply.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        2. Site Access and Use
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        You agree to:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                Use the site and game only for lawful purposes.
                            </li>
                            <li>
                                Refrain from posting or sharing content that infringes on others' rights, or that is offensive, defamatory, or otherwise unlawful.
                            </li>
                            <li>
                                Not engage in activities that may harm, disrupt, or interfere with the functionality of the site or game.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        3. Intellectual Property
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        All content on Stickman Hook, including text, graphics, logos, and media, is the property of Stickman Hook or its licensors. Unauthorized use, reproduction, or distribution of any content is strictly prohibited.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        4. User-Generated Content
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        You may contribute content, such as comments or reviews, on our website. By posting any content, you grant Stickman Hook a non-exclusive, royalty-free license to use, display, and distribute that content. We reserve the right to remove or modify any user-generated content deemed inappropriate or in violation of these Terms.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        5. Disclaimers and Limitation of Liability
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        While we strive to provide accurate and up-to-date information, Stickman Hook does not guarantee the completeness or accuracy of the content on our site or in the game. Stickman Hook is not responsible for any losses, damages, or issues arising from the use of our website or game.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        6. Termination of Use
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We reserve the right to suspend or terminate your access to Stickman Hook without notice if you violate any of these Terms of Use.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        7. Changes to Terms
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We may update these Terms of Use periodically to reflect changes in our practices. We encourage you to review this page regularly to stay informed about any updates.
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        For inquiries regarding these Terms, please contact us at <a href="mailto:support@stickmanhookgame.com" className=" text-blue-500">{`[support@stickmanhookgame.com]`}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}