import { useEffect, useState } from "react";
// import Popular from "./popular/page";
import { FaPlay } from "react-icons/fa";

function useMobilePlatform() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobilePlatforms = /android|iphone|ipod|windows phone|blackberry/i;
    const isIOS =
      /iPad|iPhone|iPod/.test(userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

    setIsMobile(mobilePlatforms.test(userAgent) || isIOS);
  }, []);

  return isMobile;
}

export default function Home() {
  const isMobile = useMobilePlatform();

  const handleFullScreen = () => {
    const iframe = document.getElementById("game-iframe");
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };

  const handleClick = () => {
    window.location.href = "#/game";
  };

  return (
    <div className="relative z-50 bg-[#5B55DB]">
      {!isMobile ? (
        <div className="2xl:flex lg:flex flex items-center justify-center 2xl:h-[85vh] lg:h-[75vh] h-[65vh] lg:mx-24 mx-8">
          <iframe
            id="game-iframe"
            src="https://stickman-hook.gamestores.fun/"
            width="100%"
            height="100%"
            frameBorder="0"
            title="Stickman Hook"
            style={{
              aspectRatio: "16 / 9",
              objectFit: "cover",
              borderRadius: "8px",
              maxWidth: "fit-content",
              border: "2px solid #FF6A84",
            }}
          ></iframe>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-[35vh] lg:mx-24 mx-8 opacity-90 rounded-lg">
          <div className="relative w-full h-full flex flex-col items-center justify-center">
            <div className="absolute inset-0 bg-playnow opacity-50"></div>
            <button
              className="w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center relative animate-zoom"
              onClick={handleClick}
            >
              <FaPlay className="text-black w-6 h-6" />
            </button>
            <a
              className="poppins-bold font-bold text-[20px] text-white mt-3 relative"
              href="#/game"
              style={{
                textShadow:
                  "1px 1px 2px black, -1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black",
              }}
            >
              Play now
            </a>
          </div>
        </div>
      )}
      <div className="flex items-center justify-center">
        <div className="container">
          {!isMobile && (
            <div className="2xl:flex lg:flex md:hidden hidden justify-center mt-6 2xl:mb-[40px] lg:mb-[28px] mb-[28px]">
              <button
                onClick={handleFullScreen}
                className="btn-full-screen 2xl:py-6 2xl:px-16 lg:py-3 lg:px-8 md:py-3 md:px-8 px-4 py-2 rounded"
              >
                <span className="lilita-one-regular 2xl:text-4xl lg:text-3xl md:text-3xl text-white">
                  Full Screen
                </span>
              </button>
            </div>
          )}
          {/* <Popular /> */}
          <div className="w-full 2xl:pt-1 lg:pt-1 pt-10 bg-[#F8FFFF]">
            <div className="2xl:mx-56 2xl:mt-14 2xl:pb-14 md:pb-14 md:mt-14 mt-10 lg:mx-20 md:mx-9 mx-6 pb-10">
              <h1 className="2xl:text-[40px] md:text-[40px] text-[32px] lilita-one-regular text-[#3D2A7D]">
                Stickman Hook: Master Grappling and Conquer Fun Challenges
              </h1>
              <div className="2xl:mt-6 md:mt-6">
                <div className="text-base md:text-lg 2xl:text-lg lg:text-lg poppins-regular text-[#383838]">
                  Stickman Hook is one of the most popular free online games,
                  captivating fans of light-hearted entertainment with its
                  simple yet challenging gameplay. With engaging mechanics and
                  levels designed to test your reflexes and strategy, this game
                  promises endless fun. Let’s explore the vibrant world of
                  Stickman Hook and discover why it’s a must-try!
                </div>
              </div>
            </div>
          </div>
          <div className="text-[#383838] lg:px-20 md:px-9 px-6 2xl:pt-20 lg:pt-20 md:pt-14 content-des 2xl:px-56 2xl:pb-20 md:pb-14 pt-14 pb-14 bg-[#F4F0FF]">
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              What Is Stickman Hook?
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Stickman Hook is a platformer game combined with unique
              physics-based mechanics. Players control a stickman character
              using a grappling hook to swing past obstacles and complete
              increasingly challenging levels. The game is not only a test of
              agility but also a game of strategy, requiring you to plan your
              moves carefully to succeed.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Why Is Stickman Hook So Popular?
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Stickman Hook boasts several features that have earned it a
              dedicated fanbase:
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl">
              1. Simple Yet Eye-Catching Graphics
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              While minimalistic, the game’s design is vibrant and visually
              appealing, offering a pleasant experience for players of all ages.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl">
              2. Fun Sound Effects
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Dynamic sound effects enhance the gameplay, making every swing and
              jump feel satisfying.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl">
              3. Addictive Gameplay
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              The increasing difficulty keeps players coming back for more.
              Whether it’s beating a challenging level or achieving a high
              score, there’s always something to strive for.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl">
              4. Suitable for All Ages
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Stickman Hook’s simplicity and charm make it perfect for both kids
              and adults looking for quick, enjoyable entertainment.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl">
              5. Play Anytime, Anywhere
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              No downloads are required—play directly on online gaming websites
              anytime you need a break.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Benefits of Playing Stickman Hook
            </h2>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl">
              Relaxation and Entertainment
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Stickman Hook is the perfect stress reliever after a busy day. The
              exciting grappling mechanics provide a fun and lighthearted
              experience.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl">
              Improved Reflexes
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              The game challenges your reflexes and reaction time, helping you
              think quickly and make precise moves.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl">
              Strategic Thinking
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Figuring out the best grappling points and the right timing to
              release the hook exercises your strategic thinking skills.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl">
              Tips for Mastering Stickman Hook
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Want to excel at Stickman Hook? Here are some expert tips:
              <ul
                style={{
                  listStyleType: "disc",
                  marginLeft: "30px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <li>
                  Observe Before You Act: Each level has a unique layout—take
                  time to study it and plan your moves.
                </li>
                <li>
                  Maximize Momentum: Release the hook at the right moment to
                  gain maximum speed and clear tricky gaps.
                </li>
                <li>
                  Don’t Fear Failure: Each attempt helps you learn and improve.
                  Embrace the trial-and-error process!
                </li>
              </ul>
              Stickman Hook is not just a game; it’s an exciting journey that
              tests your skills, creativity, and precision. Are you ready for
              the challenge? Let the adventure begin!
            </div>

            <div className="control flex items-center 2xl:mt-10 lg:mt-10 mt-4 md:mt-10">
              <div className="2xl:pl-8 2xl:py-8 lg:pl-8 lg:py-8 px-8 py-8">
                <h2 className="2xl:text-2xl lg:text-2xl md:text-2xl text-2xl font-bold text-start lilita-one-regular">
                  How to play Stickman Hook
                </h2>
                <div className="2xl:text-lg lg:text-lg text-base poppins-regular mt-2">
                  Stickman Hook’s gameplay is incredibly simple and
                  beginner-friendly:
                  <ul
                    style={{
                      listStyleType: "disc",
                      marginLeft: "30px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <li>
                      Click or tap to hook: Click the mouse or tap the screen to
                      make your stickman attach to grappling points.
                    </li>
                    <li>
                      Utilize momentum: Use the hook to build momentum, allowing
                      your stickman to swing over gaps or avoid obstacles.
                    </li>
                    <li>
                      Reach the finish line: The goal is to get your stickman
                      safely to the finish line without falling or hitting
                      obstacles.
                    </li>
                  </ul>
                  Although the controls are easy to understand, each level
                  presents unique challenges that require sharp focus and skill
                  to overcome.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
