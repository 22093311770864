import { useEffect } from "react";

export default function Policy() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Privacy Policy | Stickman Hook';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Review the Terms of Use for Stickman Hook to understand our policies on site access, content usage, and user responsibilities.");
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Stickman Hook - Play Stickman Hook Unblocked Game';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Play Stickman Hook for free on the online gaming website! Challenge yourself with unique grappling levels and overcome thrilling obstacles. Conquer Stickman Hook now!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://stickmanhookgame.com/');
            }
        };
    }, []);

    return (
        <div className="content-body mb-10 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        Privacy Policy for Stickman Hook
                    </h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Welcome to Stickman Hook. Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data while you interact with our game and services. By accessing and playing Stickman Hook, you agree to the collection and use of your information as described below.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        1. Information Collection
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We may collect personal information such as your name, email address, and usage data to improve your gaming experience. This data helps us tailor the game to your preferences and enhance your overall experience with Stickman Hook.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        2. Use of Information
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Your data allows us to:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                Customize game features, content, and recommendations based on your preferences.
                            </li>
                            <li>
                                Send you updates, newsletters, or promotional offers if you've subscribed.
                            </li>
                            <li>
                                Analyze user feedback and data to improve gameplay and performance.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        3. Data Security
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We prioritize the security of your personal information. We implement strong security measures to protect your data from unauthorized access, changes, or disclosure. Our website uses industry-standard technologies to safeguard your data.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        4. Third-Party Services
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We may collaborate with third-party providers to enhance our services. These partners may collect information necessary to perform their services on our behalf, but they are required to maintain the confidentiality of your data.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        5. Cookies
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Stickman Hook uses cookies to collect data for analytics and improve website performance. You can choose to disable cookies through your browser settings, but doing so may affect certain features of the game or website.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        6. Your Rights
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        You have the right to:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px',
                            marginBottom: '10px'
                        }}>
                            <li>
                                Access, update, or delete your personal information.
                            </li>
                            <li>
                                Opt-out of email communications and promotional offers.
                            </li>
                            <li>
                                Request information on how your data is stored and used.
                            </li>
                            <li>
                                For any questions or concerns regarding your privacy, please contact us at <a href="mailto:support@stickmanhookgame.com" className=" text-blue-500">{`[support@stickmanhookgame.com]`}</a>
                            </li>
                        </ul>
                        We are committed to safeguarding your privacy and enhancing your experience with Stickman Hook. Thank you for playing!
                    </div>
                </div>
            </div>
        </div>
    )
}