import { useEffect } from "react";

export default function ContactUs() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Contact Us | Stickman Hook';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Contact Stickman Hook for support, feedback, or inquiries. Reach out to our team for assistance with gameplay, suggestions, or business opportunities.");
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Stickman Hook - Play Stickman Hook Unblocked Game';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Play Stickman Hook for free on the online gaming website! Challenge yourself with unique grappling levels and overcome thrilling obstacles. Conquer Stickman Hook now!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://stickmanhookgame.com/');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-14vh)] lg:min-h-[calc(100vh-15vh)] md:min-h-[calc(100vh-10vh)] mb-4 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">Contact Us - Stickman Hook</h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We’d love to hear from you! Whether you have a question, feedback, or need support, our team is here to help. Reach out to us through any of the following methods, and we’ll get back to you as soon as possible.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        How to Contact Us
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                Email: <a href="mailto:support@stickmanhookgame.com" className=" text-blue-500">{`support@stickmanhookgame.com`}</a>
                            </li>
                            <li>
                                Support: If you need assistance with gameplay or have technical questions, please email our support team.
                            </li>
                            <li>
                                Feedback: We value your thoughts! Let us know your suggestions and ideas for improving Stickman Hook.
                            </li>
                            <li>
                                Business Inquiries: For partnerships or promotional opportunities, please contact us at <a href="mailto:support@stickmanhookgame.com" className=" text-blue-500">{`support@stickmanhookgame.com`}</a>
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Why Contact Us?
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginBottom: '10px'
                        }}>
                            <li>
                                Technical Support: Facing any issues with the game? We're here to help!
                            </li>
                            <li>
                                Feedback and Suggestions: Share your thoughts to help us improve your gaming experience.
                            </li>
                            <li>
                                Business Inquiries: Interested in collaborating with Stickman Hook? Reach out to us!
                            </li>
                        </ul>
                        We value our players and are dedicated to ensuring you have the best experience with Stickman Hook. Don’t hesitate to reach out—we’re here to assist you! Contact us now and start a conversation with our team!
                    </div>
                </div>
            </div>
        </div>
    )
}