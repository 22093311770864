import { useEffect } from "react";

export default function About() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'About Us | Stickman Hook';
        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', 'Play Stickman Hook online for free! Swing through challenging levels with your grappling skills and overcome obstacles in this addictive, fun game. Start now!');
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Stickman Hook - Play Stickman Hook Unblocked Game';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Play Stickman Hook for free on the online gaming website! Challenge yourself with unique grappling levels and overcome thrilling obstacles. Conquer Stickman Hook now!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://stickmanhookgame.com/');
            }
        };
    }, []);
    return (
        <div className="content-body mb-10 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="container 2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">About Stickman Hook</h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Welcome to Stickman Hook, a thrilling and addictive online game where your grappling skills are put to the test! Whether you're looking for a quick, fun distraction or an exciting challenge, Stickman Hook is here to provide endless entertainment. With simple controls, challenging obstacles, and increasingly difficult levels, it’s no wonder why this game has captivated players of all ages worldwide.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        What is Stickman Hook?
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Stickman Hook is an exciting platform game that combines physics-based mechanics with dynamic gameplay. In this game, you control a stickman using a grappling hook to swing across gaps, avoid obstacles, and reach the finish line. While the game’s controls are simple to learn, the challenges it presents will test your reflexes, timing, and problem-solving skills. Each level offers a new obstacle to conquer, making Stickman Hook a game that keeps you coming back for more.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Why Do Players Love Stickman Hook?
                    </h2>
                    <br />
                    <h2 className="2xl:text-xl lg:text-xl text-xl md:text-xl">
                        1. Simple Yet Engaging Gameplay
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        The premise is simple: tap or click to swing your stickman using the grappling hook, navigate through various obstacles, and reach the end of the level. The controls are easy to grasp, allowing anyone to jump in and start playing instantly. However, as the levels progress, the complexity increases, making each challenge more rewarding to overcome.
                    </div>
                    <br />
                    <h2 className="2xl:text-xl lg:text-xl text-xl md:text-xl">
                        2. Fun and Addictive Mechanics
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        What sets Stickman Hook apart from other games is its addictive nature. Each level becomes more intricate, with new types of obstacles and tricky gaps to swing over. The satisfaction of mastering each level, combined with the thrill of overcoming difficult challenges, keeps players hooked for hours.
                    </div>
                    <br />
                    <h2 className="2xl:text-xl lg:text-xl text-xl md:text-xl">
                        3. Beautiful and Simple Graphics
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Stickman Hook's graphics are minimalistic yet charming. The clean and vibrant design ensures that players of all ages can enjoy the game without distractions. The visual effects of the grappling hook in action add an extra layer of excitement, making each swing feel exhilarating.
                    </div>
                    <br />
                    <h2 className="2xl:text-xl lg:text-xl text-xl md:text-xl">
                        4. Progressive Difficulty
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        One of the best features of Stickman Hook is its progressive difficulty. The more you play, the more complex and challenging the levels become. With each level, new elements are introduced, requiring you to adapt your strategy and improve your skills. This gradual increase in difficulty keeps the game fresh and exciting at every turn.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Who Can Play Stickman Hook?
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Stickman Hook is a game for everyone. Whether you’re a seasoned gamer or a casual player, this game offers something for all skill levels:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                        }}>
                            <li>
                                Casual Gamers: If you’re looking for a fun, low-pressure game to enjoy in short bursts, Stickman Hook is the perfect choice. It’s easy to pick up and play, with no long commitments needed.
                            </li>
                            <li>
                                Challenge Seekers: For those who love to push their limits and tackle harder challenges, Stickman Hook’s increasing difficulty will keep you on your toes. Each new level is an opportunity to test your reflexes and perfect your grappling skills.
                            </li>
                            <li>
                                All Ages: With its intuitive controls and charming design, Stickman Hook is suitable for players of all ages. Whether you’re a child, teen, or adult, the game is designed to be accessible and enjoyable for everyone.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Why Stickman Hook is the Perfect Game for You
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                        }}>
                            <li>
                                Quick Entertainment: Whether you’re looking to kill some time or have a full gaming session, Stickman Hook is the perfect game for a quick, satisfying playthrough.
                            </li>
                            <li>
                                Relaxing Yet Challenging: While the gameplay is simple, the challenges become progressively harder, making it both relaxing and exciting.
                            </li>
                            <li>
                                Stress Relief: Playing Stickman Hook is a great way to unwind and take your mind off things. It’s the perfect escape when you need a break.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Get Ready for Your Stickman Hook Adventure!
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Stickman Hook isn’t just a game—it’s a test of your skill, timing, and creativity. With its combination of fun, challenges, and simple controls, it offers the perfect gaming experience for both casual and dedicated players. If you’re ready to put your reflexes to the test and swing your way through increasingly difficult levels, Stickman Hook is waiting for you.
                    </div>
                </div>
            </div>
        </div>
    )
}